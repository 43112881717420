import React from "react"
import Chart from "./chart"
import Header from "components/HeaderWithIcon"
import * as Styled from "./styles"
import { Empty } from "antd"
const ChartComponent = ({ periodRevenue }) => {
  return (
    <Styled.Container>
      <Header>évolution du chiffre d'affaire</Header>
      <Styled.Content>
        <Styled.ChartContainer>
          {periodRevenue.length ? (
            <Chart data={periodRevenue} />
          ) : (
              <>
                <Empty style={{ marginBottom: "40px" }} />
              </>
          )}
        </Styled.ChartContainer>
      </Styled.Content>
    </Styled.Container>
  )
}

export default ChartComponent
