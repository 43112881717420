import React, { createContext, useReducer } from "react"
import Reducer from "./reducer"
import moment from "moment"
import { DEFAULT_PERIOD } from "components/Dashboard/DashboardFilter/utils"
const initialState = {
  dashboardPeriod: DEFAULT_PERIOD,
  employeesHeaderHeight: 0,
  dashboardDate: moment().format("DD-MM-YYYY"),
}
export const Context = createContext(initialState)

export const DashboardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState)
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  )
}
