import React, { useState } from 'react'
import * as Styled from "../styles"
import { Button, Col, Image, Result, Row, Space, Typography, message } from 'antd'
import Loading from 'components/Loading'
// import TransactionCardItem from './TransactionCardItem'
import { FixedSizeList as List } from 'react-window';
import useSalonCurrency from 'hooks/useSalonCurrency'
import moneyImage from "assets/images/money-bill.png"
import { VENTE_RAPIDE_PAIEMENT_RECU_PATH } from 'routes/utils';
import { useNavigate } from 'react-router';
import { useCancelTransactionMutation } from 'actions/transactions';
import MotifModal from './MotifModal';
import { TRANSACTION_STATUS, TRANSFER_ARGENT_IMAGE, TRANSFER_ARGENT_TYPE } from 'enums/constants';

const TransactionDetails = ({ transactions = [], isLoading, isError, isIdle }) => {
    const { cancelTransaction, isError: isErroCanceling, isLoading: isCancelingTransaction } = useCancelTransactionMutation()
    const [motif, setMotif] = useState("")
    const [selectedTransactionId, setSelectedTransactionId] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate()
    const currency = useSalonCurrency()

    console.log({ transactions });

    if (isLoading || isIdle) {
        return <Loading />
    }
    if (isError) {
        return <Result status={"error"} title="Une erreur s'est produit lors de la récupération des transactions" />
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        if (!motif) return message.info("Veuillez fournir le motif d'annulation")
        cancelTransaction({ transactionId: selectedTransactionId, data: { facture: { motif_annulation: motif } } }).then(res => {
            setMotif("")
            setIsModalOpen(false);
        })
        setMotif("")
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const TransactionCardItem = ({ index, key, style }) => (
        <Styled.TransactionCardItemContaint
            align={"middle"}
            justify={"space-between"}
            gutter={20}
            key={key}
            style={style}
        >
            <Styled.TransactionCardItem
                onClick={() => navigate(VENTE_RAPIDE_PAIEMENT_RECU_PATH(transactions[index].id))}
                span={20}
            >
                <Row
                    align={"middle"}
                    justify={"space-between"}
                >
                    <Col span={15}>
                        <Space size={10}>
                            <Image src={moneyImage} width={37} height={37} />
                            <Typography.Text>{transactions[index]?.date_facture ?
                                new Intl.DateTimeFormat("fr-Fr", {
                                    dateStyle: "full",
                                    timeStyle: "medium"
                                }).format(new Date(transactions[index]?.date_facture)) :
                                "..."}</Typography.Text>
                        </Space>
                        {
                            transactions[index]?.mode_paiement === TRANSFER_ARGENT_TYPE.OM ?
                                <Styled.ImagePaiement
                                    style={{ width: 40, height: 40, borderRadius: 100, boxShadow: "-4px 4px 41px 8px rgba(0,0,0,0.18)" }}
                                    src={TRANSFER_ARGENT_IMAGE.OM}
                                    alt={transactions[index]?.mode_paiement}
                                /> :
                                transactions[index]?.mode_paiement === TRANSFER_ARGENT_TYPE.WAVE ?
                                    <Styled.ImagePaiement
                                        src={TRANSFER_ARGENT_IMAGE.WAVE} alt={transactions[index]?.mode_paiement}
                                    /> : transactions[index]?.mode_paiement ?
                                        <Typography.Paragraph
                                            style={{
                                                border: "1px solid",
                                                width: "max-content",
                                                padding: "2px 10px",
                                                marginTop: 5,
                                                borderRadius: 100,
                                                fontSize: 12,
                                                fontWeight: 500
                                            }}
                                        >{transactions[index]?.mode_paiement}</Typography.Paragraph> : null
                        }
                        {/* {transactions[index]?.mode_paiement ?
                            <Typography.Paragraph
                                style={{ border: "1px solid", width: "max-content", padding: "2px 10px", marginTop: 5, borderRadius: 100, fontSize: 12, fontWeight: 500 }}
                            >
                                {transactions[index]?.mode_paiement}
                            </Typography.Paragraph> :
                            null} */}

                    </Col>
                    <Col span={3}>
                        <Styled.Badge>{transactions[index]?.type_transaction}</Styled.Badge>
                    </Col>
                    <Col span={5} style={{ textAlign: "right" }}>
                        <Typography.Text>{transactions[index]?.montant} {currency}</Typography.Text>
                    </Col>
                </Row>
            </Styled.TransactionCardItem>
            <Col span={4} style={{ textAlign: "right" }}>
                {
                    transactions[index]?.etat === TRANSACTION_STATUS.ANNULE ?
                        <Typography.Paragraph
                            style={{
                                textAlign: "right",
                                color: "red", marginRight: 10,
                                // borderBottom: "1px solid red",
                                // width: "max-content"
                            }}
                        >
                            {TRANSACTION_STATUS.ANNULE.toUpperCase()}
                        </Typography.Paragraph> :
                        <Button
                            onClick={() => {
                                setSelectedTransactionId(transactions[index].id)
                                showModal()
                            }} danger
                        >
                            Annuler
                        </Button>
                }

            </Col>
        </Styled.TransactionCardItemContaint>
    )


    return (
        <Row>
            {
                isModalOpen && <MotifModal
                    motif={motif}
                    handleMotif={(value) => setMotif(value)}
                    isModalOpen={isModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                />
            }

            <Col span={24}>
                <Styled.TransactionDetailsContainer>
                    {/* <Styled.TransactionDate>
                        Du 24 au 31 Octobre 2023
                    </Styled.TransactionDate>
                    <Row justify={"space-between"} style={{ margin: "20px 0" }}>
                        <Col>
                            <Styled.TransactionType>Espece</Styled.TransactionType>
                        </Col>
                        <Col>
                            <Styled.TransactionAmount>110.000.0 XOF</Styled.TransactionAmount>
                        </Col>
                    </Row> */}
                    <List
                        height={600}
                        itemCount={transactions.length}
                        itemSize={100}
                        layout="vertical"
                    >
                        {TransactionCardItem}
                    </List>
                </Styled.TransactionDetailsContainer>
            </Col>
        </Row>
    )
}

export default TransactionDetails