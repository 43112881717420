import React, { useContext } from "react"
import Header from "components/SectionHeader"
import * as Styled from "./styles"
import TopServices from "components/TopServices"
import { Context } from "contexts/Dashboard"

const TopService = ({ services, title }) => {
  const [state] = useContext(Context)
  return (
    <Styled.Container style={{ height: "100%" }} id='dashboard-topServices'>
      <Styled.HeaderContainer>
        <Header height={state.employeesHeaderHeight} title={title} />
      </Styled.HeaderContainer>
      <TopServices services={services} />
    </Styled.Container>
  )
}

export default TopService
