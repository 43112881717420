import styled from "styled-components"
import { Typography } from "antd"
export const Icon = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
`
export const Heading = styled(Typography.Title)`
  display: -webkit-box;
  box-orient: vertical;
  overflow: hidden;
  font-weight: bold !important;
  margin-bottom: 0 !important;
  color: #828282 !important;
`
export const Container = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
  border: solid 2px var(--elma-border--1);
  border-radius: 8px;
  width: 100%;
  transition: all ease 500ms;
  padding: 1.2em;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
  &:hover {
    border-color: #863e8d8c;
    box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  }
`
export const Info = styled.div`
  display: block;
  h5 {
    -webkit-line-clamp: 1;
  }
`
export const AvatarContainer = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border: solid 3px var(--elma-primary);
  margin-right: 1.3em;
  padding: 2px;
`
export const Avatar = styled.div`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--elma-primary);
  div {
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`
