import useSalonId from "hooks/useSalonId"
import useEmployeeId from "hooks/useEmployeeId"
import { message } from "antd"
import { useQuery } from "react-query"
import employeesAPI from "./api"

export const useEmployeesQuery = () => {
  const salonId = useSalonId()

  return useQuery(["employees"], () => employeesAPI.getAll(salonId), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}

export const useEmployeeQuery = () => {
  const salonId = useSalonId()
  const employeeId = useEmployeeId()
  return useQuery(
    ["employee", salonId, employeeId],
    () => employeesAPI.getOne(salonId, employeeId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      onError: (data) => {
        message.error(`
      Échec du chargement des données`)
      },
    }
  )
}
