import { Col, Row, Space } from 'antd'
import React from 'react'
import * as Styled from "../../styles/index"
import useSalonCurrency from 'hooks/useSalonCurrency'

const ServiceItem = ({ label, serviceNormalPrice, promitionPrice }) => {
    const currency = useSalonCurrency()
    return (
        <Row justify={"space-between"} style={{ marginTop: 20 }}>
            <Col>
                <Styled.RecapText>
                    {label}
                </Styled.RecapText>
            </Col>
            <Col>
                {
                    promitionPrice === serviceNormalPrice ? <Styled.RecapText>
                        {serviceNormalPrice} {currency}
                    </Styled.RecapText> : <>
                            <Styled.RecapText>
                                {promitionPrice} {currency}
                            </Styled.RecapText>
                        <Styled.RecapTextUnderlined>
                            {serviceNormalPrice} {currency}
                        </Styled.RecapTextUnderlined>
                    </>
                }
            </Col>
        </Row>
    )
}

export default ServiceItem