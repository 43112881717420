import React from "react"
import AntDProvider from "contexts/antd-provider"
import AuthProvider from "contexts/Authentication"
import { AppointmentFiltersProvider } from "./AppoinmentFilters"
import { ThemeProvider } from "styled-components"
import { elmaTheme } from "styles/theme"
import { PaiementContextProvider } from "./Paiement/PaiementProvider"
import { CaisseContextProvider } from "./Caisse"
import { ServiceContextProvider } from "./Services/ServicesProvider"

const RootProvider = ({ children }) => {
  return (
    <ThemeProvider theme={elmaTheme}>
      <AntDProvider>
        <AuthProvider>
          <PaiementContextProvider>
            <CaisseContextProvider>
              <ServiceContextProvider>
                <AppointmentFiltersProvider>{children}</AppointmentFiltersProvider>
              </ServiceContextProvider>
            </CaisseContextProvider>
          </PaiementContextProvider>
        </AuthProvider>
      </AntDProvider>
    </ThemeProvider>
  )
}

export default RootProvider
