import styled from "styled-components"
import { Button } from "antd"
import { elmaBreakpoints } from "styles/theme"

export const Buttons = styled(Button)`
  padding: 10px 25px;
  background: transparent;
  border-radius: 8px;
  border: 2px solid white;
  color: white;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 14px;
  &:focus {
    color: #fff !important;
    border-color: #fff !important;
  };
  &:hover,
  &:active {
    color: #ffffff !important;
    border-color: #b679bd !important;
  };
  &:active{
    box-shadow:${({ disabled }) => disabled ? undefined : "0px -3px #b679bd !important"};
    transform: ${({ disabled }) => disabled ? undefined : "scale(.9)"}
  }

  @media screen and (max-width: ${elmaBreakpoints.sm}px) {
    & {
      padding: 5px 10px;
    }
  }
`
