import { caisseDefaultStatus } from "enums/constants";
import { caisseReducer } from "./reducer";

const { createContext, useReducer, useContext } = require("react");

const initialState = {
    caisse_status: caisseDefaultStatus
}

const CaisseContext = createContext(initialState)
export const CaisseContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(caisseReducer, initialState)

    return <CaisseContext.Provider value={{ state, filterCaisse: dispatch }}>{children}</CaisseContext.Provider>
}

export const useCaisseContext = () => {
    const ctx = useContext(CaisseContext);
    if (!ctx) throw new Error("CaisseContextProvider is not available")
    return ctx
}

export const useCaisseCurrentStatus = () => {
    const { state: { caisse_status } } = useCaisseContext()
    return { currentStatus: caisse_status }
}
export const useCaisseCurrentFilter = () => {
    const { filterCaisse } = useCaisseContext()
    return { filterCaisse }
}