const { useParams } = require("react-router")

const useAppointmentId = () => {
  const { appointmentId } = useParams()

  if (appointmentId === undefined) {
    throw new Error(`No appointment id in route param`)
  }

  return appointmentId
}

export default useAppointmentId
