import { Alert, Col, Modal, Row, Typography } from 'antd'
import Button from 'components/Button'
import { useServicesContext } from 'contexts/Services/ServicesProvider'
import { SERVICES_REDUCER_ACTION_TYPE } from 'contexts/Services/reducer'
import useSalonCurrency from 'hooks/useSalonCurrency'
import React from 'react'
import { convertMinutesTohours } from 'utils'
import * as Styled from "./styles"
import { Option, SelectContainer } from 'components/SelectDropDown'
import _ from "lodash"

const VenteRapideModal = ({ isModalOpen, handleOk, handleCancel }) => {
    const { servicesState, dispatchServices } = useServicesContext()
    const currency = useSalonCurrency()

    return (
        <Modal title="Vente Rapide"
            width={700}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Row justify={"end"} gutter={10}>
                    <Col>
                        <Button key="back" style={{ backgroundColor: "var(--elma-danger)" }} onClick={handleOk}>
                            Annuler
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            disabled={_.isEmpty(servicesState.selectedService.selectedEmploye)}
                            style={{ backgroundColor: "var(--elma-primary)" }}
                            onClick={() => {
                                dispatchServices({
                                    type: SERVICES_REDUCER_ACTION_TYPE.SELECT_SERVICE,
                                    payload: servicesState.selectedService
                                })
                                handleOk()
                            }}
                        >
                            Sauvegarder
                        </Button>
                    </Col>
                </Row>
            ]}
        >
            <Row justify={"space-between"}>
                <Col span={12}>
                    <Styled.Label>Service</Styled.Label>
                    <Styled.DescBody>
                        <Styled.Bodytext>
                            {servicesState.selectedService.name} {" "} ( {convertMinutesTohours(servicesState.selectedService.duration)} )
                        </Styled.Bodytext>
                        <Styled.Bodytext>{servicesState.selectedService.price} {currency}</Styled.Bodytext>
                    </Styled.DescBody>
                </Col>
                <Col span={12}>
                    <Styled.Label>Emis par</Styled.Label>
                    <SelectContainer
                        onSelect={(value) => {
                            console.log({ value });
                            const selectedEmployee = servicesState.selectedService.employee.filter(employe => employe.id === value)[0]
                            return dispatchServices({
                                type: SERVICES_REDUCER_ACTION_TYPE.SELECT_SERVICE_EMPLOYEE,
                                payload: selectedEmployee
                            })
                        }}
                        height={"45px"}
                        // value={values.category_id}
                        placeholder='Selectionner employé'>
                        {servicesState.selectedService.employee?.map((employe) => {
                            return (
                                <Option key={employe.id} value={employe.id}>
                                    {employe.first_name} {employe?.last_name}
                                </Option>
                            )
                        })}
                    </SelectContainer>
                    {_.isEmpty(servicesState.selectedService.selectedEmploye) && <Alert style={{ padding: "5px", fontSize: "12px", marginTop: 5 }} message="Veuillez choisir l'employé" type="warning" showIcon />}
                </Col>
            </Row>
        </Modal>
    )
}

export default VenteRapideModal