import React from "react"
import styled from "styled-components"
import { ReactComponent as LoadingSvg } from "assets/svg/loading.svg"
const FullPageSpinner = () => {
  return (
    <Container>
      <LoadingSvg className='loadingSvg' />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: #fff;
`

export default FullPageSpinner
