import React from 'react'
import { TfiStatsUp } from "react-icons/tfi";
import * as Styled from "../styles"
import useSalonCurrency from 'hooks/useSalonCurrency'

const StatCardItem = ({ label, title, bgColor, currency, content = () => { } }) => {
    const salonCurrency = useSalonCurrency()
    return (
        <Styled.Card bgColor={bgColor}>
            <Styled.Label>{label}</Styled.Label>
            <Styled.Icon>
                <TfiStatsUp size={20} color="#FFF" />
            </Styled.Icon>
            <Styled.Title>{title} {currency ? salonCurrency : null}</Styled.Title>
            {content()}
        </Styled.Card>
    )
}

export default StatCardItem