import clsx from "clsx"

/**
 * Utility functions to help persist currentSalon
 * to local storage
 */
export const salonStorage = {
  persist: (salon) => {
    try {
      console.log({ salonFromStorage: salon });
      localStorage.setItem("current_salon", JSON.stringify(salon ? salon : {}))
    } catch (error) {
      console.log(error);
    }
  },
  get: () => {
    try {
      return { currentSalon: JSON.parse(localStorage.getItem("current_salon")) }
    } catch (error) {
      console.log(error);
    }
  },
  clear: () => {
    localStorage.removeItem("current_salon")
  }
}
/**
 * Utility functions to help persist jwt token
 * to local storage
 * Tweak it as needed for your application
 */
export const authStorage = {
  /**
   * Removes auth data (token & token expiration date)
   * from local storage
   */
  clear: () => {
    localStorage.removeItem("token")
    localStorage.removeItem("tokenExpDate")
  },

  /**
   * persist auth data (token & token expiration date) to
   * local storage
   * @param {String} token - auth access token
   * @param {Number} tokenExp
   */
  persist: (token, expriseIn) => {
    // expiration date is current time + expires_in
    const tokenExpDate = new Date(new Date().getTime() + expriseIn * 1000)
    try {
      localStorage.setItem("token", token)
      localStorage.setItem("tokenExpDate", tokenExpDate)
    } catch (err) {
      console.error(err)
    }
  },
  /**
   * gets persisted auth data from local storage.
   */
  get: () => {
    try {
      return {
        token: localStorage.getItem("token"),
        tokenExpDate: localStorage.getItem("tokenExpDate"),
      }
    } catch (err) {
      console.error(err)
      // since using destructuring on undefined or null
      // value will cause an error we return an empty object.
      return {}
    }
  },
}

/**Appointment status color */
export const getAppointmentStatusColor = (status) => {}
/**
 * gets a user name followig backend schema
 */
export const getUsername = (user) => {
  const firstName = user.firstName || user.first_name
  const lastName = user.lastName || user.last_name
  return clsx(firstName, lastName).trim()
}
/**
 * gets a user initials followig backend schema
 */
export const getUserInitials = (user) => {
  const firstName = user.firstName || user.first_name
  const lastName = user.lastName || user.last_name
  return [firstName?.charAt(0), lastName?.charAt(0)]
    .filter((init) => init)
    .join("")
    .toUpperCase()
}
// get percentege diffrence between two values
export const getPercentegeDiff = (current, prev) => {
  const res = Math.floor((100 * +current) / (+current + +prev))
  return isNaN(res) ? false : res
}
/**
 * @typedef {Object} DispatchAction
 * @property {string} type - action type
 * @property {*} payload - action payload
 */

/**
 *
 * @param {string} type - action type
 * @param {*} payload - any payload type
 * @return {DispatchAction}
 */
export const createAction = (type, payload) => ({ type, payload })

/**
 *
 * @param {number} type - number type
 * @return {string} - string type
 */
export const convertMinutesTohours = (minutes = 0) => {
  if (!minutes) throw new Error("Munites must be given")

  if (typeof minutes !== "number") throw new TypeError("Munites must be a number")

  let hour = Math.floor(minutes / 60)
  let hourMinutes = Math.floor(minutes % 60)

  return hour === 0 ? `${hourMinutes}min` : hourMinutes === 0 ? `${hour}h` : `${hour}h ${hourMinutes}min`
}
