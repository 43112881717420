import React from "react"
import { useFormikContext } from "formik"
import useCurrentSalon from "hooks/useCurrentSalon"
import { Empty, List } from "antd"
import dayjs from "dayjs"
import { READABLE_DATE_FORMAT } from "enums/constants"
import ServiceItem from "./ServiceItem"
import { useEmployeesQuery } from "actions/employees"
import useSalonCurrency from "hooks/useSalonCurrency"
import * as Styled from "./styles"
const AppointmentFormInfo = () => {
  const {
    values: { services: appoinmentServices, date, remise },
    // setFieldValue,
  } = useFormikContext()

  const { data: employees = [] } = useEmployeesQuery()

  const currentSalon = useCurrentSalon()
  const currency = useSalonCurrency()

  const items = React.useMemo(() => {
    return populateFormServices(
      appoinmentServices,
      employees,
      currentSalon.services
    )
  }, [appoinmentServices, currentSalon.services, employees])
  const servicesPrice = items.reduce(
    (acc, item) =>
      acc + (+item.service.promotionalPrice || +item.service.price),
    0
  )

  let totalPrice = servicesPrice
  // React.useEffect(() => {
  //   setFieldValue("price", totalPrice)
  // }, [servicesPrice, setFieldValue, totalPrice])

  return (
    <Styled.InfoContainer>
      <Styled.SummaryContainer>
        <Styled.SummaryTitle level={2}>
          {!remise ? totalPrice : <>
            <Styled.SummaryTitleNotUnderlined>
              {totalPrice - remise}{currency}
            </Styled.SummaryTitleNotUnderlined>
            <Styled.SummaryTitleUnderlined>
              {totalPrice}{currency}
            </Styled.SummaryTitleUnderlined>
          </>
          }
        </Styled.SummaryTitle>
        <Styled.SummarySubTitle strong>
          {dayjs(date).format(READABLE_DATE_FORMAT)}
        </Styled.SummarySubTitle>
      </Styled.SummaryContainer>
      {items.length ? (
        <List
          dataSource={items}
          renderItem={({ service, employee }) => (
            <List.Item
              style={{
                borderBottom: "1px solid #ece3ee",
                marginBottom: "10px",
              }}>
              <ServiceItem key={service.id} service={service} />
            </List.Item>
          )}
        />
      ) : (
        <Empty description='Ajouter des services' />
      )}
    </Styled.InfoContainer>
  )
}

/**
 *
 * @param {array} appointmentServices - appointment services to populate
 * @param {array} employees - salon employees
 * @param {array} services - salon services
 */
export const populateFormServices = (appointmentServices, employees, services) => {
  return Array.isArray(appointmentServices) ? appointmentServices.map((item) => ({
    id: item.service,
    service: services.length ? Object.assign(
      services.find((service) => service.id === item.service),
      {
        promotionalPrice: item.promotionalPrice,
      }
    ) : {},
    employee: employees.length ? employees.find((employee) => employee.id === item.employee) : {},
  })) : []
}

AppointmentFormInfo.propTypes = {}

export default React.memo(AppointmentFormInfo)
