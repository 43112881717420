import { DATE_FILTER_PERIODS } from "components/PeriodDropdown/utils"
import { defaultAppointmentStatusOption } from "enums/constants"
import dayjs from "dayjs"

/**
 * appointment state filters
 * @readonly
 * @typedef {Object} AppointmentFiltersState
 * @property {string} dateRange - current date
 * @property {string} date - current date
 * @property {string|null} employeeId - current date
 */

/**
 * @type {AppointmentFiltersState}
 */
export const initialState = {
  dateRange: DATE_FILTER_PERIODS.THREE_DAYS,
  date: dayjs().toString(),
  employeeId: null,
  status: defaultAppointmentStatusOption.id,
}

export const actionTypes = {
  nextDate: "next_date",
  prevDate: "prev_date",
  setDateRange: "set_date_range",
  setEmployee: "set_employee",
  setStatus: "set_status",
}

/**
 * @type {payload}
 * @param {AppointmentFiltersState} state
 * @param {ActionDispatch} action
 *
 * @returns {AppointmentFiltersState}
 */
export default function (state, action) {
  switch (action.type) {
    case actionTypes.nextDate:
      return {
        ...state,
        date: dayjs(state.date).add(1, "day").toString(),
      }
    case actionTypes.prevDate:
      return {
        ...state,
        date: dayjs(state.date).subtract(1, "day").toString(),
      }
    case actionTypes.setDateRange:
      return {
        ...state,
        dateRange: action.payload,
      }
    case actionTypes.setEmployee:
      return {
        ...state,
        employeeId: action.payload,
      }
    case actionTypes.setStatus:
      return {
        ...state,
        status: action.payload,
      }
    default:
      throw new Error(`unknown action=${action.type}`)
  }
}
