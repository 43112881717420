import { useQuery } from "react-query";
import { statistiquesApi } from "./api";
import useSalonId from "hooks/useSalonId";

export const useStatistiques = ({ params }) => {
    const salonId = useSalonId()
    return useQuery(["statistiques", params], () => statistiquesApi.getStatistiques({ salonId, params }), {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        retry: false,
    })
}