import styled from "styled-components"
import { Layout, Typography } from "antd"
import { elmaBreakpoints } from "styles/theme"
export const Container = styled(Layout)`
  height: 100vh;

  @media screen and (max-width: 557px) {
    width: ${(props) => (props.collapsed ? "" : "100vh")};
  }
`

export const Header = styled(Layout.Header)`
  height: calc(50px + 2 * 0.75rem);
  line-height: initial;
  padding: 0.75rem 24px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.06);
  position: ${({ sticky }) => (sticky ? "sticky" : "")};
  top: 0;
  z-index: 1;
  background-color: #ffff;
`

export const Content = styled(Layout.Content)`
  padding: 24px 24px 200px;
  height: 100vh;
  overflow: ${({ scrollable }) => (scrollable ? "auto" : "unset")};
`
export const HeaderTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
  font-weight: bolder !important;
  color: #fff !important;
  text-transform: uppercase;
  color: #282828 !important;
  @media screen and (max-width: ${elmaBreakpoints.xs}px) {
    font-size: 17px !important;
  }
`
