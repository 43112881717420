import React from "react"
import Card from "./card"
import { Space, Empty } from "antd"
const TopServices = ({ services }) => {
  return (
    <Space style={{ width: "100%" }} direction='vertical'>
      {services.length ? (
        services.map((service) => {
          return <Card {...service} />
        })
      ) : (
        <Empty style={{ marginTop: "40px" }} />
      )}
    </Space>
  )
}
export default TopServices
