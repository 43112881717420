import styled from "styled-components"
import { Typography } from "antd"
export const HeaderTitle = styled.div`
  svg {
    margin-right: 1.1em;
  }
  span {
    font-size: 18px;
    color: #282828;
    font-weight: bolder;
    text-transform: uppercase;
  }
`
export const CardTitle = styled(Typography.Title)`
  color: #fff;
  margin: 0;
  font-size: 64px;
  font-weight: 300;
  line-height: 56.88px;
`
export const Header = styled.div`
  display: flex;
  padding: 20px 36px;
  align-items: center;
  border-bottom: solid 2px #f8f8f8;
`
export const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 700px;
`
export const Content = styled.div`
  padding: 26px 36px;
  overflow: hidden;
  overflow-x: auto;
`
export const Container = styled.div`
  background-color: #fff;
  border: solid 2px var(--elma-border--1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  height: 100%;
`
