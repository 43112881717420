import { Typography } from "antd";
import styled from "styled-components";
import { elmaBreakpoints } from "styles/theme";

export const Card = styled.div`
    border-radius: 8px;
    padding: 10px;
    background-color: ${({ bgColor }) => bgColor};
    `
export const Label = styled(Typography.Text)`
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 500;
    text-transform: uppercase;
`
export const Title = styled(Typography.Title)`
    color: #FFFFFF !important;
`
export const Icon = styled.div`
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  width: 35px;
  height: 35px;
  align-items: center;
  align-self: flex-start;
  margin-left: auto;
`
export const FilterPeriodContainerStyled = styled.div`
  width: 100%;
  margin: 10px 0px;
  min-height: 65px;
  padding: 20px;
  background-color: #fff;
  box-shadow: var(--elma-shadow--9);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  @media screen and (max-width: ${elmaBreakpoints.md}px) {
    justify-content: center;
  }
`