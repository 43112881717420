import React from "react"
import { ReactComponent as DotsSvg } from "assets/svg/dots.svg"
import { Col, Row, Typography } from "antd"
import * as Styled from "./styles"
const HeaderWithIcon = ({
  children,
  borderColor = "#eaeaea",
  bordered = false,
  action,
  icon = DotsSvg,
}) => {
  return (
    <Styled.Header borderColor={borderColor} bordered={bordered}>
      <Row
        gutter={[0, { xs: action ? 16 : 0, md: 0 }]}
        style={{ width: "100%" }}>
        <Col style={{ display: "flex", alignItems: "center" }} xs={24} md={19}>
          <Styled.Title>
            <Styled.IconWrapper>{React.createElement(icon)}</Styled.IconWrapper>
            <Typography.Text>{children}</Typography.Text>
          </Styled.Title>
        </Col>
        {action && (
          <Col style={{ display: "flex", alignItems: "center" }} xs={24} md={5}>
            {action}
          </Col>
        )}
      </Row>
    </Styled.Header>
  )
}
export default HeaderWithIcon
