import { Col } from 'antd'
import React from 'react'
import SearchInputCaisse from '../SearchInput/SearchForfait'
import * as Styled from "./styles"
import TransactionDetails from './TransactionDetails/TransactionDetails'
import { useAllTransactionsQuery } from 'actions/transactions'
import TransactionFilterPeriod from './PeriodFilter/TransactionFilterPeriod'

const CaisseTransactions = () => {
    const { data: transactions = [], isLoading, isError, isIdle } = useAllTransactionsQuery()
    return (
        <>
            <Styled.TransactionsFilterContainer>
                <Styled.TransactionMainContent align={"middle"}>
                    {/* <Col span={16}> */}
                        {/* <Styled.ButtonSwitch active={true} style={{ backgroundColor: "--var(elma-primary)" }}>Par jour</Styled.ButtonSwitch>
                            <Styled.ButtonSwitch>Par Semaine</Styled.ButtonSwitch>
                            <Styled.ButtonSwitch>Par Mois</Styled.ButtonSwitch>
                            <Styled.ButtonSwitch>Par Annees</Styled.ButtonSwitch>
                            <Styled.ButtonSwitch>Personnalise</Styled.ButtonSwitch> */}
                    {/* <TransactionFilterPeriod />
                    </Col>
                    <Col span={8}>
                        <SearchInputCaisse placeholder={"Rechercher par client"} />
                    </Col> */}
                </Styled.TransactionMainContent>
            </Styled.TransactionsFilterContainer>

            <div style={{ marginTop: 20 }}>
                <TransactionDetails
                    transactions={transactions}
                    isLoading={isLoading}
                    isError={isError}
                    isIdle={isIdle}
                />
            </div>
        </>
    )
}

export default CaisseTransactions