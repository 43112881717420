import React, { createContext, useReducer } from "react"
import Reducer from "./reducer"
const initialState = {
  collapsed: true,
}
export const Context = createContext(initialState)

export const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState)
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  )
}
