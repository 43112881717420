import React from "react"
import * as Styled from "./styles"
import { ReactComponent as DecorationSvg } from "assets/svg/illustrator-2.svg"
const NewAppointmentBanner = ({ title }) => {
  return (
    <Styled.Container>
      <Styled.HeaderTitle level={3}>{title}</Styled.HeaderTitle>
      <Styled.Decoration>
        <DecorationSvg />
      </Styled.Decoration>
    </Styled.Container>
  )
}

export default NewAppointmentBanner
