import { Col, Form, Input, Row, Typography } from "antd";
import styled from "styled-components";

export const SelectContainer = styled.div`
    background-color: #ffffff;
    // max-width: 300px;
    width: 100%;
    height: 80vh;
    overflow: hidden;
`
export const GoBack = styled(Typography.Title)`
    color: var(--elma-primary);
    text-transform: uppercase;
    cursor: pointer;
    transition: all 200ms ease;
    &:hover{
        transform: scale(.98)
    }
    &:active{
        transform: scale(.96)
    }
`
export const GoBackText = styled(Typography.Text)`
    &:active{
        transform: scale(.95)
    }
`
export const FormSearch = styled(Form)`
margin: 30px 0px;
.ant-input-affix-wrapper:hover {
    border-color: #000  !important;
    border-right-width: 1px;
};
.ant-input-affix-wrapper:hover, .ant-input-affix-wrapper-focused{
border-color: var(--elma-primary) !important;
};
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #000  !important;
    border-right-width: 1px;
    z-index: 1;
};
.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled:hover {
    border-color: #000  !important;
    border-right-width: 1px;
    z-index: 1;
};
.ant-input-affix-wrapper:hover {
    border-color: #000 !important;
    border-right-width: 1px;
}
`
export const InputSearch = styled(Input)`
border-radius: 8px;
    ::placeholder{
        font-size:14px !important;
    }
`
export const NewClient = styled(Row)`
    border-bottom: 2px dashed #DDD;
    background-color: #ddd;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: all ease 300ms;
    margin-bottom: 40px;
    trasition: all ease 200ms;
    &:active, &:hover{
        transform: scale(.99)
    }
`
export const NewClientText = styled(Typography.Text)`
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`
export const ClientsContainer = styled.div`
    height: 100%;
    overflow: auto;
    padding-bottom: 300px;
    padding-left: 10px;
    padding-right: 20px;
`
export const ClientListItem = styled(Row)`
    margin-top: 10px;
    padding: 10px;
    border-bottom: ${({ selected }) => selected ? undefined : "2px dashed #DDD"};
    cursor: ${({ selected }) => selected ? undefined : "pointer"};
    transition: all 100ms ease-in-out;
    border-radius: 8px;
    box-shadow:${({ selected }) => selected ? "0px 4px 14px 0px rgba(0, 0, 0, 0.15)" : undefined};

    &:hover{
        background-color: ${({ selected }) => selected ? undefined : "#DDD"}
    }
    &:active{
        transform: scale(.95)
    }
`
export const ClientName = styled(Typography.Text)`
    color: #000;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
`
export const ClientPhone = styled(Typography.Paragraph)`
    color: #555;
    font-size: 12px;
    margin-bottom: 0px !important;
`
export const AndtResult = styled.div`
    .ant-result-title{
        font-size: 18px;
    }
    .ant-result-subtitle{
        font-size: 12px;
    }
    .ant-result-icon > .anticon {
    font-size: 54px;
}
`
export const SearchText = styled(Typography.Paragraph)`
    font-style: italic;
`
export const ResetSelectedClient = styled(Col)`
    cursor: pointer;
    boder-radius: 8px;
    padding: 0px 30px;
    &:hover{
        border: 1px solid #ddd;
    }
`