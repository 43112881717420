import { Layout } from "antd"
import React from "react"
import PropTypes from "prop-types"
import useMediaQuery from "hooks/useMediaQuery"
import { useElmaTheme } from "styles/theme"
import { Context } from "contexts/Layout"
const ReponsiveSider = ({ children }) => {
  const theme = useElmaTheme()
  const isUnderSM = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`)
  const [state] = React.useContext(Context)
  if (isUnderSM) {
    return (
      <Layout.Sider
        style={{ overflow: "hidden", overflowY: "auto", backgroundColor: "var(--elma-primary)" }}
        breakpoint='sm'
        trigger={null}
        collapsedWidth={0}
        defaultCollapsed
        collapsed={state.collapsed ?? false}
        width={300}
        collapsible>
        {children}
      </Layout.Sider>
    )
  }
  return (
    <Layout.Sider
      style={{ overflow: "hidden", overflowY: "auto", backgroundColor: "var(--elma-primary)" }}
      trigger={null}
      defaultCollapsed
      collapsed={state.collapsed}
      width={300}
      collapsible>
      {children}
    </Layout.Sider>
  )
}

ReponsiveSider.propTypes = {
  // collapsed: PropTypes.bool,
}

export default ReponsiveSider
