import { Alert, Result, Space, Typography } from 'antd'
import Divider from 'components/Divider'
import { useServicesContext } from 'contexts/Services/ServicesProvider'
import ExpeditionCardItem from 'features/paiementProcedure/components/Expedition/ExpeditionCardItem'
import ServiceItem from 'features/paiementProcedure/components/RecapPaiement/ServiceItem'
import TitlePaiement from 'features/paiementProcedure/components/TitlePaiement'
import React from 'react'
import TotalPaiement from './TotalPaiement'
import Input from 'components/Input';
import * as Styled from "./styles"
import { useFormikContext } from 'formik'
import _ from "lodash"

const Services = ({ services }) => {
    return <>
        {
            services.length ? services?.map(service => {
                return <ServiceItem
                    label={`${service.name} - géré ${service.selectedEmploye.first_name} ${service.selectedEmploye?.last_name ?? ''}`}
                    promitionPrice={+service.price}
                    serviceNormalPrice={+service.price}
                />
            }) : null
        }
    </>
}

const VenteRapidePaiementRecap = () => {
    const { servicesState } = useServicesContext()
    const { setFieldValue, values, setFieldError, errors } = useFormikContext()
    return (
        <div style={{ padding: 20 }}>
            <TitlePaiement>Récapitulatif du service</TitlePaiement>
            <Services services={servicesState.panier} />
            <Divider mt={20} mb={15} />
            <ExpeditionCardItem label={"Client"} name={`${servicesState.selectedClient.firstName} ${servicesState.selectedClient?.lastName ?? ''}`} />
            <Divider mt={20} mb={15} />
            {/* <Styled.FormItem label='Remise'>
            </Styled.FormItem> */}
            <div style={{ marginBottom: 20 }}>
                <Typography.Text>Remise</Typography.Text>
                <Input
                    // readOnly
                    autoFocus
                    error={!_.isEmpty(errors?.remise)}
                    // error={true}
                    max={Number(values.totalPaiement)}
                    type="number"
                    name="remise"
                    value={values.direct_sale.remise}
                    onChange={(e) => {
                        if (Number(values.totalPaiement) < Number(e.target.value)) {
                            return setFieldError("remise", "La remise peut pas être supérieur au prix total")
                        }
                        setFieldError("remise", "")
                        setFieldValue("direct_sale", { ...values.direct_sale, remise: Number(e.target.value) })
                    }}
                    placeholder='Montant remise'
                />
                {
                    errors?.remise && <Alert
                        style={{ padding: "5px", fontSize: "12px", marginTop: 5 }}
                        message={errors?.remise} type='error'
                        showIcon
                    />
                }
            </div>
            <TotalPaiement />
        </div>
    )
}

export default VenteRapidePaiementRecap