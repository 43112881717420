import CaisseClientInfoItem from 'components/Caisse/CaisseClientInfo/CaisseClientInfoItem'
import React from 'react'
import Panier from './Panier/Panier'

const CaisseClientInfo = () => {
    return (
        <div>
            <CaisseClientInfoItem />
            <Panier />
        </div>
    )
}

export default CaisseClientInfo