import AppLayout from 'components/Layout'
import React from 'react'
import VenteRapidePaiement from '../../../components/VenteRapide/VenteRapidePaiement';
import BackButtonLink from 'components/BackButtonLink';
import { VENTE_RAPIDE_PAIEMENT_RECU_PATH, getCaisseHomePath } from 'routes/utils';
import useSalonId from 'hooks/useSalonId';
import { Route, Routes } from 'react-router';
import RecuVenteRapide from '../RecuVenteRapide/RecuVenteRapide';

const HandlePaimentVenteRapide = () => {
    const salonId = useSalonId()
    return <>
        <AppLayout.Header title={"Paiement vente rapide"} />
        <AppLayout.Content scrollAble>
            <BackButtonLink to={getCaisseHomePath(salonId)}>
                Procéder au paiement
            </BackButtonLink>
            <VenteRapidePaiement />
        </AppLayout.Content>
    </>
}

const PaimentVenteRapide = () => {
    return (
        <Routes>
            <Route path='' element={<HandlePaimentVenteRapide />} />
            <Route path={VENTE_RAPIDE_PAIEMENT_RECU_PATH()} element={<RecuVenteRapide />} />
        </Routes>
    )
}

export default PaimentVenteRapide