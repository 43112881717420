import { Input, Space, Typography, Form, Row, Tabs } from "antd";
import styled from "styled-components";

export const ClientSelect = styled(Row)`
    border: 1.132px dashed rgba(221, 221, 221, 0.87);
    background: #FFF;
    padding: 10px;
    width: 100%;
    transition: all ease 200ms;
    &:hover{
        background-color: rgba(221, 221, 221, 0.87);
        border-radius: 5px;
    }
    &:active{
        transform: scale(.95)
    }
`
export const ClientNameSelect = styled(Typography.Text)`
font-size: calc(6px + .5vw);
font-style: italic;
font-weight: 400;
line-height: normal;
`
export const ServiceItemContainer = styled.div`
    background-color: #fff;
    min-height: 170px;
    box-shadow: ${({ active }) => active ? "0px 4px 14px #65376A" : "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"} ;
    padding: 10px;
    border: 1px solid rgba(27, 31, 35, 0.15);
    cursor: pointer;
    margin: 2px;
`
export const ServiceTitle = styled(Typography.Paragraph)`
color: #707070;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
margin:0 !important;
`
export const ServiceDescription = styled(Typography.Paragraph)`
color: #707070;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 26.334px; 
margin-bottom: 5px !important;
`
export const ServicePrice = styled(Typography.Text)`
color: #121212;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 35.112px;
`
export const FormSearch = styled(Form)`
.ant-input-affix-wrapper:hover {
    border-color: #000  !important;
    border-right-width: 1px;
};
.ant-input-affix-wrapper:hover, .ant-input-affix-wrapper-focused{
border-color: var(--elma-primary) !important;
};
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #000  !important;
    border-right-width: 1px;
    z-index: 1;
};
.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled:hover {
    border-color: #000  !important;
    border-right-width: 1px;
    z-index: 1;
};
.ant-input-affix-wrapper:hover {
    border-color: #000 !important;
    border-right-width: 1px;
}
`
export const InputSearch = styled(Input)`
border-radius: 8px;
    ::placeholder{
        font-size:14px !important;
    }
`
export const CaisseContainer = styled.div`
    margin-top: 20px;
`
export const CaisseState = styled.div`
    background-color: #fff;
    border: 1px solid #DDD;
    padding: 20px;
    height: 100%;
    `
export const ClientInfo = styled.div`
    background-color: #fff;
    border: 1px solid #DDD;
    padding: 20px;
    height: 100%;
`
export const VenteMainContaint = styled(Row)`
    min-height: 100vh;
    position: sticky;
    // width: 100%;
    // top: 500px;
`
export const VenteTabs = styled(Tabs)`
    .ant-tabs-tab-btn{
        font-size: 18px;    
        font-weight: 500;
        color: #B7B7B7
    };
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #000000;
        text-shadow: 0 0 0.25px currentcolor;
    };
    .ant-tabs-ink-bar {
        height: 5px;
        background: #000000 !important;
    }
`
export const VenteTitle = styled(Typography.Text)`
    font-size: 18px;
    color: #555;
    font-weight: 400;
`