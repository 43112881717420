import styled from "styled-components"
import React from "react"

const UserBlock = ({ color, name, percentage }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "5px",
      }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}>
        <Circle
          style={{
            backgroundColor: color,
          }}></Circle>
        <P
          style={{
            marginLeft: "10px",
          }}>
          {name}
        </P>
      </div>
      <div>
        <P>{percentage}</P>
      </div>
    </div>
  )
}

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: black;
`
const P = styled.p`
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: "#333333";
`

export default UserBlock
