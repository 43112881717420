import { createGlobalStyle } from "styled-components"
const GlobalStyles = createGlobalStyle`
  body{
    font-family: "Poppins", sans-serif;
    margin: 0 !important;
    *{
      &::-webkit-scrollbar {
    width: var(--elma-scrollbar--width);
    }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--elma-scrollbar-track--color);
      border-radius:  var(--elma-scrollbar--radius);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--elma-scrollbar-thumb--color);
    border-radius:  var(--elma-scrollbar--radius);
  }
    }
  }
`
export default GlobalStyles
