import styled, { css } from "styled-components"
import { Typography } from "antd"
import { elmaBreakpoints } from "styles/theme"

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1.5rem;
  overflow: auto;
  padding-bottom: 1.5rem;
`

const ActiveStatus = css`
  color: var(--elma-primary) !important;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 30%;
    animation: slide 500ms forwards;
    background-color: var(--elma-primary);
  }
  @keyframes slide {
    from {
      width: 0%;
    }
    to {
      width: 30%;
    }
  }
`
export const StatusTitle = styled(Typography.Title)`
  padding: 0.5rem 0.75rem;
  margin: 0 !important;
  margin-right: 1rem;
  color: #b1b1b1 !important;
  cursor: pointer;
  transition: all ease 500ms;
  text-transform: uppercase;
  white-space: nowrap;
  ${({ active }) => active && ActiveStatus}
  @media screen and (max-width: ${elmaBreakpoints.xs}px) {
    font-size: 16px !important;
  }
`
