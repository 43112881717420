import { css } from "styled-components"
export const pickerStyles = css`
  border-radius: 8px !important;
  border-color: #eaeaea !important;
  border-width: 2px !important;
  border-style: solid;
  display: flex;
  align-items: center;
  padding: 9px 20px !important;
  height: 45px !important;
`
