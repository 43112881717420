import React, { useState, useEffect, useContext, useRef } from "react"
import Header from "components/SectionHeader"
import CollaboratorCard from "./card"
import Button from "components/Button"
import { Col, Empty } from "antd"
import { elmaBreakpoints } from "styles/theme"
import { ChevronLeft, ChevronRight } from "react-feather"
import { Context } from "contexts/Dashboard"
import * as Styled from "./styles" // data
const WeeklyRevenue = ({ employeesEarnings = [], title }) => {
  const headerRef = useRef()
  const shuffleArray = (array) => {
    let currentIndex = array?.length,
      temporaryValue,
      randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }
  const data = shuffleArray(employeesEarnings)
  // eslint-disable-next-line
  const [collaborators, setCollaborator] = useState(data)
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(1)
  // eslint-disable-next-line
  const [CollabPerPage, setCollabPerPage] = useState(6)

  // get the current page and how much we will display
  const indexOfLastPost = currentPage * CollabPerPage
  const indexOfFirstPost = indexOfLastPost - CollabPerPage
  const currentCollabs = data.slice(indexOfFirstPost, indexOfLastPost)

  // get the number of pages that we got
  const numberOfPages = []

  for (let i = 1; i <= Math.ceil(data.length / CollabPerPage); i++) {
    numberOfPages.push(i)
  }

  // get current header height
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateState = React.useCallback(() => {
    if (window.innerWidth > elmaBreakpoints.md) {
      dispatch({
        type: "EMPLOYEES_HEIGHT_UPDATE",
        payload: headerRef.current.offsetHeight,
      })
    }
  })
  useEffect(() => {
    dispatch({
      type: "EMPLOYEES_HEIGHT_UPDATE",
      payload: headerRef.current.offsetHeight,
    })
    window.addEventListener("resize", updateState)
    return () => {
      window.removeEventListener("resize", updateState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Styled.Container id='dashboard-weeklyRevenue'>
      <Header
        ref={headerRef}
        id='employees_header'
        title={title ?? `CA par collaborateur`}
      >
        <Button
          disabled={currentPage === 1 ? true : false}
          onClick={() => setCurrentPage(currentPage - 1)}>
          <ChevronLeft /> <Styled.Span>PREC</Styled.Span>
        </Button>
        <Button
          style={{ marginLeft: "15px" }}
          disabled={currentPage === numberOfPages.length ? true : false}
          onClick={() => setCurrentPage(currentPage + 1)}>
          <Styled.Span>SUIV</Styled.Span>
          <ChevronRight />
        </Button>
      </Header>
      <Styled.EditedRow
        empty={!currentCollabs.length}
        gutter={[{ xs: 0, md: 8 }, 7]}
        style={{ width: "100%", marginTop: "8px" }}>
        {currentCollabs.length ? (
          currentCollabs.map((collab) => {
            return (
              <Col
                style={{
                  marginBottom: "8px",
                  display: "flex",
                  justifyConent: "flex-end",
                }}
                xs={24}
                md={12}>
                <CollaboratorCard user={collab} />
              </Col>
            )
          })
        ) : (
          <Empty style={{ marginTop: "40px" }} />
        )}
      </Styled.EditedRow>
    </Styled.Container>
  )
}
export default WeeklyRevenue
