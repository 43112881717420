import { useEmployeesQuery } from 'actions/employees'
import { useFormikContext } from 'formik'
import React from 'react'
import useCurrentSalon from './useCurrentSalon'
import { populateFormServices } from 'components/Appointment/AppointmentFormInfo'

const useAppointmentRecap = () => {
    const {
        values: { services: appoinmentServices, reductionPrix = "", status, remise },
    } = useFormikContext()
    const { data: employees = [] } = useEmployeesQuery()
    const currentSalon = useCurrentSalon()

    const items = React.useMemo(() => {
        return populateFormServices(
            appoinmentServices,
            employees,
            currentSalon.services
        )
    }, [appoinmentServices, currentSalon.services, employees])

    const servicesPrice = items.reduce(
        (acc, item) =>
            acc + (+item.service.promotionalPrice || +item.service.price),
        0
    )
    let totalPrice = servicesPrice

    return { reductionPrix, totalPrice, status, remise }
}

export default useAppointmentRecap