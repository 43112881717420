import React from 'react'
import * as Styled from "../DashboardSalonRevenue/styles"
import { Space, Typography } from 'antd'
import useSalonCurrency from 'hooks/useSalonCurrency'
import { compareTurnOver } from '../utils'

const TurnOverItem = ({ globalTurnOver, turnOver, color, label }) => {
    const currency = useSalonCurrency()
    return (
        <Styled.Tax color={color}>
            <Space align="center" wrap>
                {label} :
                <Styled.CA> {turnOver} {currency}</Styled.CA>
                <Typography.Text style={{ color: color, fontSize: 11 }}>
                    ({compareTurnOver(globalTurnOver, turnOver)} du CA)
                </Typography.Text>
            </Space>
        </Styled.Tax>
    )
}

export default TurnOverItem