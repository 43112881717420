/**
 *
 * @param {string|number} salonId - salon id to generate path for
 */
export const getSalonPath = (salonId) => `/salons/${salonId}`

/**
 *
 * @param {string|number} salonId
 */
export const getAppointmentsListBasePath = (salonId) =>
  `/salons/${salonId}/appointments`
/**
*
* @param {string|number} salonId
*/
export const getAppointmentsRecuPaiementPath = (salonId, appointmentId) =>
  `/salons/${salonId}/appointments/${appointmentId}/recu-paiement`

/**
 *
 * @param {string|number} salonId
 * @param {string|number} appointmentId
 */
export const getAppointmentBasePath = (salonId, appointmentId) =>
  `/salons/${salonId}/appointments/${appointmentId}`

/**
 *
 * @param {string|number} salonId
 * @param {string|number} appointmentId
 */
export const getAppointmentEditPath = (salonId, appointmentId) =>
  `${getAppointmentBasePath(salonId, appointmentId)}/edit`
export const getAppointmentPaiementPath = (salonId, appointmentId) =>
  `${getAppointmentBasePath(salonId, appointmentId)}/paiement`

/**
 *
 * @param {string|number} salonId
 */
export const getNewAppointmentPath = (salonId) =>
  `/salons/${salonId}/appointments/new`
/**
 *
 * @param {string|number} salonId
 */
export const getSalonSettingsPath = (salonId) => `/salons/${salonId}/settings`

/**
 *
 * @param {string|number} salonId
 */
export const getNewClientPath = (salonId = ":salonId") =>
  `/salons/${salonId}/clients/new`
/**
 *
 * @param {string|number} salonId
 */
export const getClientHomePath = (salonId = ":salonId") =>
  `/salons/${salonId}/clients`

export const getClientPath = (salonId = ":salonId", clientId = ":clientId") =>
  `/salons/${salonId}/clients/${clientId}`

export const getCategoriesHomePath = (salonId = ":salonId") =>
  `/salons/${salonId}/categories`
export const getNewCategoryPath = (salonId = ":salonId") =>
  `/salons/${salonId}/categories/new`
export const getEditCategoryPath = (salonId, categoryId = ":categoryId") =>
  `${getCategoriesHomePath(salonId)}/edit/${categoryId}`

export const getEditCategoryBasePath = (salonId) =>
  `${getCategoriesHomePath(salonId)}/edit`

export const getEmployeeHomePath = (salonId = ":salonId") =>
  `/salons/${salonId}/employees`
export const getEmployeeEditPath = (salonId, employeeId = ":employeeId") =>
  `${getEmployeeHomePath(salonId)}/edit/${employeeId}`

export const getEditEmployeeBasePath = (salonId) =>
  `${getCategoriesHomePath(salonId)}/edit`
export const getNewEmployeePath = (salonId = ":salonId") =>
  `${getEmployeeHomePath(salonId)}/new`

//packs
export const getPackHomePath = (salonId = ":salonId") =>
  `/salons/${salonId}/packs`

export const getNewPackPath = (salonId = ":salonId") =>
  `/salons/${salonId}/packs/new`

export const getAgendaPath = (salonId = ":salonId") =>
  `/salons/${salonId}/agenda`

// caisse
export const getCaisseHomePath = (salonId = ":salonId") =>
  `/salons/${salonId}/caisse`
export const getCaissePaiemenVenteRapidePath = (salonId = ":salonId") =>
  `/salons/${salonId}/caisse/vente-rapide-paiement`

export const CAISSE_VENTE_RAPIDE_PAIEMENT_PATH = "vente-rapide-paiement/*"
export const VENTE_RAPIDE_PAIEMENT_RECU_PATH = (venteId = ":venteId") => `recu-rapide-paiement/${venteId}`