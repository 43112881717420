import React, { useContext } from "react"
import styled from "styled-components"
import UserDropdown from "./UserDropdown"
import useMediaQuery from "hooks/useMediaQuery"
import { useElmaTheme } from "styles/theme"
import { ReactComponent as MenuTrigger } from "assets/svg/menu-trigger.svg"
import { Context } from "contexts/Layout"
const Navbar = ({ children }) => {
  const [state, dispatch] = useContext(Context)
  const toggleSider = () => {
    dispatch({ type: "OPEN_MENU", payload: !state.collapsed })
  }
  const theme = useElmaTheme()
  const isUnderSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`)
  return (
    <Container>
      {isUnderSm ? (
        <MenuTrigger
          onClick={toggleSider}
          style={{ marginRight: "20px", cursor: "pointer" }}
          fill={"#333333"}
        />
      ) : (
        ""
      )}
      {children}
      <LeftContent>
        <UserDropdown />
      </LeftContent>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const LeftContent = styled.div`
  margin-left: auto;
`

export default Navbar
