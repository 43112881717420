import React from "react"

export const createReducerCtx = (reducer, initialState) => {
  const defaultDispatch = () => initialState // we never actually use this

  const ctx = React.createContext(initialState)
  // just to mock out the dispatch type and make it not optioanl
  const dispatchCtx = React.createContext(defaultDispatch)

  function Provider({ initial, children, ...props }) {
    const [state, dispatch] = React.useReducer(reducer, initial || initialState)

    return (
      <dispatchCtx.Provider value={dispatch}>
        <ctx.Provider value={state} {...props}>
          {children}
        </ctx.Provider>
      </dispatchCtx.Provider>
    )
  }

  function useCtx() {
    const c = React.useContext(ctx)
    if (!c) throw new Error("useCtx must be inside a Provider with a value")
    return c
  }
  function useDispatchCtx() {
    const c = React.useContext(dispatchCtx)
    if (!c)
      throw new Error("useDispatchCtx must be inside a Provider with a value")
    return c
  }
  return [useCtx, useDispatchCtx, Provider]
}
