import { useRecuPaiementVenteRapideQuery } from 'actions/recuPaiement'
import React from 'react'
import RecuViewFeature from 'features/RecuPaiement/RecuPaiement'
import { getCaisseHomePath } from 'routes/utils'
import useSalonId from 'hooks/useSalonId'

const RecuVenteRapide = () => {
    const { status, data, isError } = useRecuPaiementVenteRapideQuery()
    const salonId = useSalonId()

    return (
        <>
            <RecuViewFeature
                status={status}
                data={data}
                isError={isError}
                title="Details reçu vente rapide"
                navigateBack={getCaisseHomePath(salonId)}
                navigateText="Vente Rapide"
            />
        </>
    )
}

export default RecuVenteRapide