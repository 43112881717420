import styled from "styled-components"
import { Typography } from "antd"
export const SummaryContainer = styled.div`
  background-color: #f8f2ff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 15px;
`
export const ServiceItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const InfoContainer = styled.div``

export const SummaryTitle = styled(Typography.Title)`
  color: var(--elma-primary) !important;
  margin: 0 !important;
  text-align: center;
`
export const SummaryTitleUnderlined = styled(Typography.Text)`
  text-decoration:line-through;
  display: block;
`
export const SummaryTitleNotUnderlined = styled(Typography.Text)`
color: var(--elma-primary) !important;
display: block;
`
export const SummarySubTitle = styled(Typography.Text)`
  color: #a278a7;
  font-size: 15px;
  text-transform: uppercase;
`
