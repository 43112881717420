import React, { useEffect } from 'react'
import TitlePaiement from '../TitlePaiement'
import * as Styled from "../../styles/index"
import { Space } from 'antd'
// import CrediCard from './CrediCard/CrediCard'
import PaiementOptions from './components/PaiementOptions'
// import EspecePaiement from './Espece/EspecePaiement'
// import TransfertArgent from './TransfertArgent./TransfertArgent'
import { useFormikContext } from 'formik'
// import { PAIEMENT_TYPE_STATUS } from 'enums/constants'
import useAppointmentRecap from 'hooks/useAppointmentRecap'
import useAppointmentId from 'hooks/useAppointmentId'
import ButtonPaiement from '../ButtonPaiement'

export const MainPaiementOption = () => {
    const { isSubmitting, handleSubmit } = useFormikContext()
    return <Styled.MainContent>
        <TitlePaiement>Paiement</TitlePaiement>
        <Space style={{ width: "100%", marginTop: "20px" }}>
            <Styled.PayerAvecText>Payer avec: </Styled.PayerAvecText>
        </Space>
        {/* OPTIONS DE PAIEMENTS */}
        <PaiementOptions />
        {/* <Row style={{ marginTop: "30px" }}>
            <Col span={24}>
                {
                    values.facture.mode_paiement === PAIEMENT_TYPE_STATUS.CREDIT_CARD ? <CrediCard /> :
                        values.facture.mode_paiement === PAIEMENT_TYPE_STATUS.ESPECE ? <EspecePaiement /> :
                            values.facture.mode_paiement === PAIEMENT_TYPE_STATUS.TRANSFERT_ARGETNT ? <TransfertArgent /> :
                                <Result
                                    status="warning"
                                    title="Il n'exist pas ce type de paiement"
                                />
                }
            </Col>
        </Row> */}
        <Styled.BtnContainer>
            <ButtonPaiement isSubmitting={isSubmitting} onClick={handleSubmit} >PAYER</ButtonPaiement>
        </Styled.BtnContainer>
    </Styled.MainContent>
}

const Paiement = () => {
    const { setFieldValue, values } = useFormikContext()
    const appointmentId = useAppointmentId()
    let { totalPrice } = useAppointmentRecap()
    useEffect(() => {
        // ! we set the facture object for paiement
        setFieldValue("facture", { ...values.facture, montant: totalPrice, appointment_id: appointmentId })
    }, [totalPrice, appointmentId])
    return (
        <MainPaiementOption />
    )
}

export default Paiement