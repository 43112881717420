import ForfaitList from 'components/Caisse/Forfait/ForfaitList'
import SearchInputCaisse from 'components/Caisse/SearchInput/SearchForfait'
import React from 'react'

const CaisseForfait = () => {
    return (
        <>
            <SearchInputCaisse placeholder={"Rechercher des forfaits"} />
            <ForfaitList />
        </>
    )
}

export default CaisseForfait