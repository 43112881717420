import React from 'react'
import * as Styled from "../styles"

const EmployeCardItem = ({ employe, active, changeEmploye }) => {
    return <Styled.EmployeCardItem onClick={() => changeEmploye(employe)} active={active}>{employe.first_name} {employe?.last_name}</Styled.EmployeCardItem>
}

const SelectEmploye = ({ employees = [], selectedEmploye, changeEmploye }) => {
    return (
        <Styled.EmployeCardItemContainer>
            <Styled.EmployeCardItemMainContain>
                {
                    employees.map(employe => <EmployeCardItem changeEmploye={changeEmploye} active={employe.id === selectedEmploye.id} employe={employe} />)
                }
            </Styled.EmployeCardItemMainContain>
        </Styled.EmployeCardItemContainer>
    )
}

export default SelectEmploye