import React, { useEffect, useState, useRef } from "react"
import Chartjs from "chart.js/auto"
import moment from "moment"
import colors from "styles/export.less"
import useSalonCurrency from "hooks/useSalonCurrency"

const Chart = ({ data }) => {
  const chartContainer = useRef(null)
  const [chartInstance, setChartInstance] = useState(null)
  const currency = useSalonCurrency()
  const chartConfig = {
    type: "line",
    data: {
      labels: data.map(
        (item) => item.day || moment(item.month.toString(), "M").format("MMM")
      ),
      datasets: [
        {
          // label: "xi le3ba",
          data: data.map((item) => item.total),
          fill: false,
          backgroundColor: "rgba(114, 92, 164, 0.2)",
          //borderColor: "rgba(114, 92, 164, 0.8)",
          pointBackgroundColor: colors.elmaPrimary,
          pointRadius: 5,
          lineTension: 0.5,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      bezierCurve: true,
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              color: "#FFF",
              zeroLineColor: "#F8FBFE",
              zeroLineWidth: 2,
            },
            ticks: {
              stepSize: 10000,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              color: "#F9F9F9",
              zeroLineColor: "#F9F9F9",
              zeroLineWidth: 1,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "#E5E5E5",
        titleFontColor: "#725CA4",
        bodyAlign: "center",
        bodyFontColor: "#725CA4",
        displayColors: false,
        bodyFontSize: 16,
        xPadding: 17,
        yPadding: 9,
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.yLabel + ` ${currency}`
          },
          title: function (toolTipItem) {
            return ""
          },
        },
      },
    },
  }
  useEffect(() => {
    if (chartInstance !== null) {
      chartInstance.data.labels = data.map(
        (item) => item.day || moment(item.month.toString(), "M").format("MMM")
      )
      chartInstance.data.datasets[0].data = data.map((item) => item.total)
      chartInstance.update()
    }
  }, [chartInstance, data])
  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig)
      setChartInstance(newChartInstance)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContainer])

  return <canvas width={920} height={250} ref={chartContainer} />
}

export default Chart
