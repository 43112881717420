import styled from "styled-components"
import { Select } from "antd"
import { pickerStyles } from "styles/components/Pickers"
export const SelectContainer = styled(Select)`
  width: 100%;

  .ant-select-selector {
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    };
    ${pickerStyles}
    width:100%;
    text-align: left;
    padding: 20px 20px !important;
    height: ${({ height }) => height && height} !important;
  }
`
export const Option = styled(Select.Option)``
