const Reducer = (state, action) => {
  switch (action.type) {
    case "OPEN_MENU":
      return {
        ...state,
        collapsed: action.payload,
      }
    default:
      return state
  }
}

export default Reducer
