import Button from 'components/Button'
import React from 'react'

const ButtonPaiement = ({ children, onClick, isSubmitting }) => {
    return (
        <Button
            onClick={onClick}
            disabled={isSubmitting ? true : false}
            style={{
            backgroundColor: "var(--elma-primary)",
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center"
        }}>
            {isSubmitting ? "Paiement en cours..." : children}
        </Button>
    )
}

export default ButtonPaiement