import request from "utils/request"
/**
 *
 * @param {string|number} salonId
 * @param {number} serviceId - service id
 */
const remove = ({ salonId, serviceId }) => {
  if (!serviceId) {
    throw new Error("No service id was provided")
  }

  return request({
    url: `backoffice/salons/${salonId}/services/${serviceId}`,
    method: "DELETE",
  })
}
const update = ({ salonId, id: serviceId, service }) => {
  if (!service) {
    throw new Error("No service was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/services/${serviceId}`,
    params: { service },
    method: "PATCH",
  })
}
const add = ({ salonId, service }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/services/`,
    params: { service },
    method: "POST",
  })
}
const getOne = ({ salonId, serviceId }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/services/${serviceId}`,
  })
}
const getAll = ({ salonId }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/services/`,
  })
}
const servicesAPI = {
  remove,
  getOne,
  getAll,
  update,
  add,
}
export default servicesAPI
