import { Container, StatusTitle } from 'components/Appointment/Navigation/styles'
import { useCaisseCurrentFilter, useCaisseCurrentStatus } from 'contexts/Caisse'
import { caisseActions } from 'contexts/Caisse/reducer'
import { caisseOptions } from 'enums/constants'
import React, { useCallback } from 'react'
import { createAction } from 'utils'

const StatusNavigation = () => {
    const { filterCaisse } = useCaisseCurrentFilter()
    const { currentStatus } = useCaisseCurrentStatus()

    const setCaisseStatus = useCallback((id) => {
        const action = createAction(caisseActions.set_status, id)
        filterCaisse(action)
    }, [filterCaisse])

    return (
        <Container>
            {caisseOptions.map((status) => {
                return (
                    <StatusTitle
                        key={status.id}
                        onClick={() => setCaisseStatus(status.id)}
                        active={status.id === currentStatus}
                        level={4}>
                        {status.title}
                    </StatusTitle>
                )
            })}
        </Container>
    )
}

export default StatusNavigation