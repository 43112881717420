import styled from "styled-components"
export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-top: 15px;
  cursor: pointer;
  margin-bottom: 10px;
  span {
    &:first-child {
      margin-right: 10px;
    }
    color: ${({ type }) =>
      type === "primary" ? "var(--elma-primary)" : "#fff"};
  }
`
