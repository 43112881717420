import { Col } from 'antd'
import React from 'react'
import * as Styled from "../styles"
import CaisseVente from './CaisseVente/CaisseVente'
import CaisseClientInfo from './CaisseClientInfo/CaisseClientInfo'

const NouvelEncaissement = () => {
    return (
        <Styled.VenteMainContaint gutter={10}>
            <Col span={16} style={{ flexShrink: 2 }}>
                <Styled.CaisseState>
                    <CaisseVente />
                </Styled.CaisseState>
            </Col>
            <Col span={8} style={{ flexShrink: 1 }}>
                <Styled.ClientInfo>
                    <CaisseClientInfo />
                </Styled.ClientInfo>
            </Col>
        </Styled.VenteMainContaint>
    )
}

export default NouvelEncaissement