import React from "react"
import { LeftOutlined } from "@ant-design/icons"
import { Typography } from "antd"
import { Link } from "react-router-dom"
import * as Styled from "./styles"
const { Text } = Typography
const BackButton = ({ to, type, padding }) => {
  return (
    <Link to={to}>
      <Styled.Container style={{ padding }} type={type}>
        <LeftOutlined />
        <Text>Retour</Text>
      </Styled.Container>
    </Link>
  )
}
export default BackButton
