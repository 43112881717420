import React from "react"
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons"
import { Typography } from "antd"
import { Context } from "contexts/Dashboard"
import * as Styled from "./styles"
import { getPercentegeDiff } from "utils"
import { comparisonText } from "enums/constants"
const { Text } = Typography
const Card = ({ data, color, title }) => {
  const [state] = React.useContext(Context)
  return (
    <Styled.CardContent
      style={{
        backgroundColor: color,
      }}>
      <Styled.Flex>
        <Styled.CardTitle level={1}>{data?.current || 0}</Styled.CardTitle>
        {data?.current > data?.prev ? (
          <Styled.Icon>
            <ArrowUpOutlined
              style={{
                fontSize: "20px",
              }}
            />
          </Styled.Icon>
        ) : (
          <Styled.Icon>
            <ArrowDownOutlined
              style={{
                fontSize: "20px",
              }}
            />
          </Styled.Icon>
        )}
      </Styled.Flex>
      <Styled.Block>
        <Text style={{ fontSize: "16px" }} level={4}>
          Rendez-vous&nbsp;
          {title}
        </Text>
        <br />
        <Text level={6}>
          {`${data?.current > data?.prev ? "+" : "-"}${
            getPercentegeDiff(data?.current, data?.prev) || "N/A"
          }% `}
          {comparisonText[state.dashboardPeriod]}
        </Text>
      </Styled.Block>
    </Styled.CardContent>
  )
}

export default Card
