import request from "utils/request"

/**
 *
 * @param {string|number} salonId - salon id to fetch clients for
 * @param {string} keyword - keyword to search the clients with
 */
const getAll = (salonId, keyword) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/clients`,
    params: { keyword },
  })
}

/**
 *
 * @param {string|number} salonId - salon id to fetch clients for
 * @param {string} keyword - keyword to search the clients with
 */
const getOne = (salonId, clientId) => {
  if (!salonId || !clientId) {
    throw new Error("No salonId/clientId was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/clients/${clientId}`,
  })
}

const add = ({ salonId, user }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }

  return request({
    url: `backoffice/salons/${salonId}/clients/new`,
    params: { user },
    method: "POST",
  })
}

const edit = ({ salonId, infoToUpdate, userId }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }

  return request({
    url: `backoffice/salons/${salonId}/clients/${userId}`,
    params: { user: infoToUpdate },
    method: "PATCH",
  })
}

const clientsAPI = {
  getAll,
  add,
  getOne,
  edit
}

export default clientsAPI
