import React from 'react'
import ClientListItem from './ClientListItem'
import { useClientsQuery } from 'actions/clients'
import Loading from 'components/Loading'
import { Result } from 'antd'
import * as Styled from "../styles"
import { useServicesContext } from 'contexts/Services/ServicesProvider'
import { SERVICES_REDUCER_ACTION_TYPE } from 'contexts/Services/reducer'

const ClientsList = ({ onClose }) => {
    const { servicesState, dispatchServices } = useServicesContext()
    const { data: clients = [], isIdle, isLoading, isError } = useClientsQuery(servicesState.clientSearch)

    if (isIdle || isLoading) {
        return <>
            {
                servicesState.clientSearch && <Styled.SearchText> Résultats de recheches pour "{servicesState.clientSearch}"</Styled.SearchText>
            }
            <Loading />
        </>
    }
    if (isError) {
        return <>
            {
                servicesState.clientSearch && <Styled.SearchText> Résultats de recheches pour "{servicesState.clientSearch}"</Styled.SearchText>
            }
            <Result status={"error"} title="Une erreur est survenue lors de la récupération des clients" />
        </>
    }
    return (
        <Styled.ClientsContainer>
            {
                servicesState.clientSearch && <Styled.SearchText>Résultats de recheches pour "{servicesState.clientSearch}"</Styled.SearchText>
            }
            {
                clients.length ? clients.map(client => {
                    console.log({ client });
                    return <ClientListItem client={client} onClick={(client) => {
                        dispatchServices({
                            type: SERVICES_REDUCER_ACTION_TYPE.SELECT_CLIENT,
                            payload: client
                        })
                        // ! we close the client select list
                        onClose()
                    }} />
                }) :
                    <Styled.AndtResult>
                    <Result style={{ fontSize: 14 }} subTitle={"Ajouter un nouveau client"} status={"info"} title="Pas encore des clients" />
                </Styled.AndtResult>
            }
        </Styled.ClientsContainer>
    )
}

export default ClientsList