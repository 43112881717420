import React from "react"
import * as Styled from "./styles"
const SectionHeader = (props, ref) => {
  return (
    <Styled.Container
      ref={ref}
      style={{ display: props.title ? "flex" : "none", height: props.height }}>
      <Styled.TitleContainer
        children={props.children}
        style={{ width: props.children ? "50%" : "100%" }}>
        <Styled.HeaderTitle children={props.children} level={2}>
          {props.title}
        </Styled.HeaderTitle>
      </Styled.TitleContainer>
      {props.children ? (
        <Styled.ChildrenContainer>{props.children}</Styled.ChildrenContainer>
      ) : null}
    </Styled.Container>
  )
}
export default React.forwardRef(SectionHeader)
