import { useMutation } from "react-query"
import { venteRapideApi } from "./api"
import { message } from "antd"

export const useVenteRapideMutation = () => {
    const { mutateAsync: addVenteRapide, isLoading, isIdle, isError, isSuccess } = useMutation(venteRapideApi.createVenteRapide, {
        onSuccess: () => {
            message.success("Vente rapide effectuée avec succès")
        },
        onError: (error) => {
            console.log({ error });
            message.error("Une erreur s'est produite")
        }
    })

    return { addVenteRapide, isLoading, isIdle, isError, isSuccess }
}