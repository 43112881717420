import request from "utils/request"

/**
 *
 * @param {object} params - login params (email, password)
 */
const login = (params) => {
  return request({
    url: "user/sign_in",
    method: "POST",
    params: { user: params },
  })
}

const logout = () => {
  return request({
    url: "user/sign_out",
    method: "DELETE",
  })
}

/**
 * Fetches currently logged in user
 */
export const fetchUser = () => {
  return request({
    url: "backoffice/users/current",
    method: "GET",
  })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  logout,
  fetchUser,
}
