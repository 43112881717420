import styled from "styled-components"
import { Typography } from "antd"
import { elmaBreakpoints } from "styles/theme"
export const Space = styled.div`
  @media screen and (min-width: ${elmaBreakpoints.md}px) {
    padding-right: 15px;
  }
  @media screen and (max-width: ${elmaBreakpoints.sm}px) {
    padding-bottom: 15px;
  }
`
export const HeaderTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
  font-weight: bolder !important;
  color: #fff !important;
  text-transform: uppercase;
  color: #282828 !important;
`
