/**
 * @enum
 * @readonly
 */
export const SERVICES_REDUCER_ACTION_TYPE = {
    SET_SETVICES: "SET",
    RESET_VENTE_RAPIDE_SETVICES: "RESET_VENTE_RAPIDE_SETVICES",
    SEARCH_SERVICE: "SEARCH_SERVICE",
    SELECT_SERVICE: "SELECT_SERVICE",
    SET_SELECTED_SERVICE: "SET_SELECTED_SERVICE",
    REMOVE_SELECTED_SERVICE: "REMOVE_SELECTED_SERVICE",
    SELECT_SERVICE_EMPLOYEE: "SELECT_SERVICE_EMPLOYEE",
    CHANGE_SERVICE_EMPLOYEE: "CHANGE_SERVICE_EMPLOYEE",
    SELECT_CLIENT: "SELECT_CLIENT",
    RESET_SELECTED_CLIENT: "RESET_SELECTED_CLIENT",
    CLIENT_SEARCH: "CLIENT_SEARCH"
}

export const servicesReducer = (state, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case SERVICES_REDUCER_ACTION_TYPE.SET_SETVICES: {
            return {
                allServices: action.payload,
                salonsServices: action.payload,
                selectedService: {},
                selectedClient: {},
                panier: [],
                totalPrixInPanier: 0,
                remise: null,
                clientSearch: "",
                serviceSearch: ""
            };
        }
        case SERVICES_REDUCER_ACTION_TYPE.RESET_VENTE_RAPIDE_SETVICES: {
            return {
                ...state,
                panier: [],
                selectedService: {},
                selectedClient: {},
                totalPrixInPanier: 0,
                remise: null,
                allServices: state.allServices.map(service => ({ ...service, selected: false })),
                salonsServices: state.allServices.map(service => ({ ...service, selected: false })),
            };
        }
        case SERVICES_REDUCER_ACTION_TYPE.SEARCH_SERVICE: {
            return {
                ...state,
                serviceSearch: action.payload,
                salonsServices: action.payload !== "" ?
                    state.allServices.filter(service => service.name.toLowerCase().includes(action.payload)) :
                    state.allServices
            }
        }
        case SERVICES_REDUCER_ACTION_TYPE.CLIENT_SEARCH: {
            return {
                ...state,
                clientSearch: action.payload
            }
        }
        // ! add new service in cart
        case SERVICES_REDUCER_ACTION_TYPE.SELECT_SERVICE: {
            // const selectedService = state.salonsServices.filter(service => service.id === action.payload)[0]
            return {
                ...state,
                allServices: state.allServices.map(service => service.id === action.payload.id ? { ...service, selected: true } : service),
                salonsServices: state.salonsServices.map(service => service.id === action.payload.id ? { ...service, selected: true } : service),
                panier: [...state.panier, action.payload],
                totalPrixInPanier: [...state.panier, action.payload].reduce((acc, currentService) => acc + Number(currentService.price), 0)
            }
        }
        // ! remove service from cart
        case SERVICES_REDUCER_ACTION_TYPE.REMOVE_SELECTED_SERVICE: {
            return {
                ...state,
                allServices: state.salonsServices.map(service => service.id === action.payload ? { ...service, selected: false } : service),
                salonsServices: state.salonsServices.map(service => service.id === action.payload ? { ...service, selected: false } : service),
                panier: state.panier.filter(service => service.id !== action.payload)
            }
        }
        case SERVICES_REDUCER_ACTION_TYPE.SELECT_SERVICE_EMPLOYEE: {
            return {
                ...state,
                selectedService: { ...state.selectedService, selectedEmploye: action.payload },
                // panier: state.panier.map(item => item.id === action.payload.id ? { ...item, selectedEmploye: action.payload } : item),
            }
        }
        case SERVICES_REDUCER_ACTION_TYPE.CHANGE_SERVICE_EMPLOYEE: {
            return {
                ...state,
                panier: state.panier.map(service => service.id === action.payload.service.id ? { ...service, selectedEmploye: action.payload.employe } : service),
                // panier: state.panier.map(item => item.id === action.payload.id ? { ...item, selectedEmploye: action.payload } : item),
            }
        }
        case SERVICES_REDUCER_ACTION_TYPE.SET_SELECTED_SERVICE: {
            return {
                ...state,
                selectedService: action.payload
            }
        }
        case SERVICES_REDUCER_ACTION_TYPE.SELECT_CLIENT: {
            return {
                ...state,
                selectedClient: action.payload
            }
        }
        case SERVICES_REDUCER_ACTION_TYPE.RESET_SELECTED_CLIENT: {
            return {
                ...state,
                selectedClient: {}
            }
        }
    }

}