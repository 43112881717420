import React, { useContext } from "react"
import { Layout } from "antd"
import SideNavMenu from "components/Layout/SideNavMenu"
import Navbar from "components/Navbar"
import { LayoutProvider, Context } from "contexts/Layout"
import * as Styled from "./styles"
import ReponsiveSider from "components/Layout/ResponsiveSider"
const LayoutHeader = ({ children, title, spaceBetween, sticky }) => {
  return (
    <Styled.Header sticky={sticky} style={{ marginBottom: spaceBetween }}>
      <Navbar>
        {title ? (
          <Styled.HeaderTitle level={4}>{title}</Styled.HeaderTitle>
        ) : (
          ""
        )}
        {children}
      </Navbar>
    </Styled.Header>
  )
}

const LayoutContent = ({ children, scrollAble }) => (
  <Styled.Content scrollable={scrollAble}>{children}</Styled.Content>
)

const AppLayout = ({ children }) => {
  return (
    <LayoutProvider>
      <AppLayoutContent>{children}</AppLayoutContent>
    </LayoutProvider>
  )
}
const AppLayoutContent = ({ children }) => {
  const [state] = useContext(Context)
  return (
    <Styled.Container collapsed={state.collapsed}>
      <ReponsiveSider>
        <SideNavMenu />
      </ReponsiveSider>
      <Layout>{children}</Layout>
    </Styled.Container>
  )
}

// this way you can use these like so
// <AppLayout.Header>some stuff</AppLayout.Header>
AppLayout.Header = LayoutHeader

AppLayout.Content = LayoutContent

export default AppLayout
