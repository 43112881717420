import React from 'react'
import { useSpring, animated } from '@react-spring/web'

const EnterFromLeft = ({ children }) => {
    const springs = useSpring({
        from: {
            opacity: 0,
            // y: '6%',
        },
        to: {
            opacity: 1,
            // y: 0,
        },
    })

    return (
        <animated.div
            style={{
                width: "100%", height: "100%",
                ...springs,
            }}
        >
            {children}
        </animated.div>
    )
}

export default EnterFromLeft