import salonsAPI from 'actions/salons/api';
import { message } from 'antd';
import { useAuthContext } from 'contexts/Authentication';
import SuspenseSpinner from 'pages/SuspenseSpinner';
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router';
import { getSalonPath } from 'routes/utils';

const CheckAuthPage = () => {
    const { logout } = useAuthContext()
    //! here we get the current user associated salon
    // const { data: salons = [], isLoading, isRefetching } = useQuery(["salons"], salonsAPI.getAll, {
    //     refetchOnWindowFocus: false,
    //     refetchOnMount: false,
    // })
    const [loadingSalon, setLoadingSalon] = useState(true)
    const [salon, setSalon] = useState(null)


    useEffect(() => {
        // ! we fetch currentUser salon
        salonsAPI.fetchSalon(setLoadingSalon, setSalon)
    }, [])

    if (loadingSalon) {
        return <SuspenseSpinner />
    }
    if (!salon) {
        logout()
        message.error("Vous n'avez pas de salon")
        return <Navigate to={"/login"} />
    }

    // route to redirect to incase we didn't find target route
    const redirectRoute = getSalonPath(salon.id)

    // console.log({ redirectRoute });
    // if the cuerrent salonId doesn't exist
    // we redirect to a valid workspace
    // if (!salons.some((s) => s.id === salonId)) {
    //     return <Navigate to={redirectRoute} />
    // }
    return <Navigate to={redirectRoute} />
}

export default CheckAuthPage