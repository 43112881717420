import React from 'react'
import * as Styled from "../styles"
import { Space } from 'antd'
import { ArrowLeft } from 'react-feather'

const NavigateBack = ({ onClose }) => {
    return (
        <Styled.GoBack level={5} onClick={onClose}>
            <Space align="center" size={5}>
                <ArrowLeft color='var(--elma-primary)' />
                <Styled.GoBackText style={{ color: "var(--elma-primary)", verticalAlign: "top" }}>Selectionner un client</Styled.GoBackText>
            </Space>
        </Styled.GoBack>
    )
}

export default NavigateBack