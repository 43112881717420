import React from 'react'
import * as Styled from "../styles"
import { Space } from 'antd'
import DatePicker from "components/DatePicker"
import { Option, SelectContainer } from 'components/SelectDropDown'
import { DATE_FILTER_FORMATS, OPTIONS_PERIOD_FILTER } from 'components/Dashboard/DashboardFilter/utils'
import moment from 'moment'
import { useStatistiqueContext } from 'contexts/Statistiques/StatistiquesProvider'
import { STATISTIQUE_REDUCER_ACTION_TYPE } from 'contexts/Statistiques/reducer'

const StatPeriodFilter = () => {
    const { statistique, dispatchStatistiques } = useStatistiqueContext()
    const defaultValue = statistique.period
    const [picker, setPicker] = React.useState(defaultValue)

    const changePeriod = (value) => {
        setPicker(value)
        return dispatchStatistiques({
            type: STATISTIQUE_REDUCER_ACTION_TYPE.CHANGE_PERIOD,
            payload: value
        })
    }
    const changeDate = (value) => {
        console.log({ value: moment(value).format("DD-MM-YYYY") });
        return dispatchStatistiques({
            type: STATISTIQUE_REDUCER_ACTION_TYPE.CHANGE_DATE,
            payload: value
        })
    }
    return (
        <Styled.FilterPeriodContainerStyled>
            <Space size='middle'>
                <DatePicker
                    allowClear={false}
                    defaultValue={moment()}
                    format={DATE_FILTER_FORMATS(new Date())}
                    picker={picker}
                    disabledDate={null}
                    onChange={(val) => changeDate(val)}
                    style={{ padding: "20px 20px!important ", height: "100% !important" }}
                />
                <SelectContainer
                    defaultValue={defaultValue}
                    onChange={(val) => changePeriod(val)}
                    placeholder='Sort Type'>
                    {OPTIONS_PERIOD_FILTER.map((val) => (
                        <Option key={val.key} value={val.key}>{val.text}</Option>
                    ))}
                </SelectContainer>
            </Space>
        </Styled.FilterPeriodContainerStyled>
    )
}

export default StatPeriodFilter