// import { Col, Row } from 'antd'
// import useSalonCurrency from 'hooks/useSalonCurrency'
import React from 'react'
import * as Styled from "../styles"
// import { useServicesContext } from 'contexts/Services/ServicesProvider'

const TotalPanier = () => {
    // const currency = useSalonCurrency()
    // const { servicesState } = useServicesContext()
    return (
        <Styled.TotalPanier justify={"space-between"}>
            <Styled.TotalLabel>Total</Styled.TotalLabel>
            {/* <Styled.TotalPrice>{servicesState.totalPrixInPanier} {currency}</Styled.TotalPrice> */}
        </Styled.TotalPanier>
    )
}

export default TotalPanier