import React, { Suspense } from "react"
import AppLayout from "components/Layout"
import { Routes, Route, Navigate } from "react-router-dom"
import AuthNestedRoutes from "./AuthNestedRoutes"
import SuspenseSpinner from "pages/SuspenseSpinner"

const AuthenticatedApp = () => {

  return (
    <AppLayout>
      <Suspense fallback={<SuspenseSpinner />}>
        <Routes>
          {/* <Route path="/" element={<Navigate to={"/salons/:salonId"} />} /> */}
          {/* <Route path="/salons" element={<Navigate to={"/salons/:salonId"} />} /> */}
          <Route path="/*" element={<AuthNestedRoutes />} />
        </Routes>
      </Suspense>
    </AppLayout>
  )
}

export default React.memo(AuthenticatedApp)
