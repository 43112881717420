import React from 'react'
import * as Styled from '../../styles/index'
import { Col, Image, Row, Typography } from 'antd'
import { Printer, Send } from 'react-feather'
import successIcon from "assets/images/success-icon.png"

const RecuHeader = ({ handlePrint }) => {
    return (
        <>
            <Row justify={"end"} gutter={20}>
                <Styled.FlexCol onClick={handlePrint}>
                    <Printer color='var(--elma-primary)' /> Print
                </Styled.FlexCol>
                <Styled.FlexCol>
                    <Send color='var(--elma-primary)' /> Envoyer
                </Styled.FlexCol>
            </Row>
            <Row justify={"center"} gutter={20} style={{ textAlign: "center" }}>
                <Col span={24}>
                    <Image src={successIcon} width={50} height={50} />
                </Col>
                <Col span={24}>
                    <Typography.Title level={4} style={{ marginTop: 20 }}>Paiement réussi !</Typography.Title>
                </Col>
            </Row>
        </>
    )
}

export default RecuHeader