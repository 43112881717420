export const caisseActions = {
    set_status: "set_status",
}

export function caisseReducer(state, action) {
    switch (action.type) {
        case caisseActions.set_status: {
            return {
                ...state,
                caisse_status: action.payload
            }
        }
    }
    throw Error('Unknown action: ' + action.type);
}