import React from "react"
import * as Styled from "./styles"
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ style, disabled, onClick, children }) => {
  return (
    <Styled.Buttons
      style={{
        ...style,
        color: disabled ? " #ffffff" : null,
        border: disabled ? "2px solid  #ffffff" : null,
        fontWeight: disabled ? "500" : "700",
        opacity: disabled ? ".5" : 1,
        // cursor: disabled ? "default" : "pointer"
      }}
      shack={!disabled}
      disabled={disabled}
      onClick={onClick}
      ghost>
      {children}
    </Styled.Buttons>
  )
}
