import React, { useState } from 'react'
import * as Styled from "../styles"
import { Col, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ClientCreationForm from 'components/ClientCreationForm'

const AddNewClientModal = () => {
    const [isModal, setIsModal] = useState(false)
    return (
        <>
            <Modal
                open={isModal}
                onCancel={() => setIsModal(false)}
                width={800}
                footer={null}
            >
                <ClientCreationForm isModal={isModal} closeModal={() => setIsModal(false)} onSubmit={() => setIsModal(false)} />
            </Modal>
            <Styled.NewClient gutter={10} onClick={() => setIsModal(true)} >
                <Col>
                    <PlusOutlined size={20} color='var(--elma-primary)' />
                </Col>
                <Col>
                    <Styled.NewClientText>Ajouter un nouveau client</Styled.NewClientText>
                </Col>
            </Styled.NewClient>
        </>
    )
}

export default AddNewClientModal