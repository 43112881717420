const { default: useCurrentSalon } = require("./useCurrentSalon")

const currencies = {
  dakar: "XOF",
  Casablanca: "Dhs",
}

const useSalonCurrency = () => {
  const currentSalon = useCurrentSalon()
  // we fallback to "$" in case there isn't any city/currency
  return currentSalon.currency || currencies[currentSalon.city]
}

export default useSalonCurrency
