import React from "react"
import { LogOut } from "react-feather"
import { ReactComponent as MenuTrigger } from "assets/svg/menu-trigger.svg"
import { Context } from "contexts/Layout"
import * as Styled from "./style/"

import PropTypes from "prop-types"

const SideMenu = React.memo(({ children, toggle, toggleSlide, logout }) => {
  /* eslint-disable no-unused-vars */
  const [state, dispatch] = React.useContext(Context)
  const ref = React.useRef(null)

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch({ type: "OPEN_MENU", payload: true })
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, dispatch])

  return (
    <div ref={ref}>
      <Styled.leftNavbar show={toggle}>
        <Styled.Nav>
          <div>
            <Styled.NavLogo onClick={toggleSlide}>
              <MenuTrigger fill={"#FFF"} />
              <Styled.NavName
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  letterSpacing: "2px",
                  textTransform: "uppercase",
                  color: "white",
                  paddingLeft: "4px",
                }}
                show={!toggle}>
                KILIYA.co
              </Styled.NavName>
            </Styled.NavLogo>
            <div>{children}</div>
          </div>

          <Styled.NavLinkk onClick={logout}>
            <Styled.StyledIcon as={LogOut} size={30} />
            <Styled.NavName show={!toggle}>Déconnexion</Styled.NavName>
          </Styled.NavLinkk>
        </Styled.Nav>
      </Styled.leftNavbar>
    </div>
  )
})

const SideBarItem = ({ to, icon, children, toggle, exact }) => {
  return (
    <Styled.NavLinkRouter to={to}>
      <Styled.StyledIcon size={30} as={icon} />
      <Styled.NavName show={!toggle}>{children}</Styled.NavName>
    </Styled.NavLinkRouter>
  )
}

SideBarItem.prototype = {
  icon: PropTypes.elementType,
  children: PropTypes.string,
}

export { SideMenu, SideBarItem }
