import { Col, Form, Image, Input, Layout, Radio, Row, Table, Typography } from "antd";
import styled from "styled-components";

export const Container = styled.div`
    height: 120vh;
`
export const PaiementMain = styled(Layout.Content)`
    padding: 20px;
    margin-top: 20px;
    height: 100%;
`
export const PaiementRow = styled(Row)`
    height: 100%;
    overflow: auto;
`
export const LeftSide = styled(Col)`
    border-right: 1px solid #D9D9D9
`
export const RightSide = styled(Col)`
    background-color: #F9FAFA
`
export const MainContent = styled.div`
    height: 100%;
    padding: 20px;
`
export const RecapServicesContainer = styled.div`
    // margin-top: 20px;
    padding: 0px 0 0px 0;
`
export const RecapText = styled(Typography.Text)`
    font-weight: 500
`
export const RecapTextUnderlined = styled(Typography.Paragraph)`
    font-weight: 400;
    margin: 0px !important;
    text-decoration: line-through;
    font-size: 12px;
`
export const ExpeditionText = styled(Typography.Text)`
    font-weight: 500;
    font-size: 14px;
`
export const BtnStyle = styled.button`
    border: none;
    padding: 10px 20px;
    background-color: #ACACAC;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 500;
    width:100%;
    transition: all ease 200ms;
    cursor: pointer;

    &:active{
        transform: scale(0.98)
    }
`
export const TotalDesc = styled(Typography.Paragraph)`
    font-size: 12px;
    font-weight: 300
`
export const TotalPrice = styled(Typography.Text)`
    font-weight: 600;
    font-size: 20px;
`
export const PayerAvecText = styled(Typography.Paragraph)`
    font-weight: 500
`
export const RadioStyle = styled(Radio)`
    color: gray;
    .ant-radio-checked .ant-radio-inner{
        border-color: var(--elma-primary) !important ;
    }

    .ant-radio-checked .ant-radio-inner:after{
        background-color: var(--elma-primary);
    }

    .ant-radio:hover .ant-radio-inner {
        border-color: var(--elma-primary) ;
    }
    .ant-radio-checked .ant-radio-inner:focus{
        border-color: var(--elma-primary);
    }
`

export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    margin-left: 0px;
    label {
      font-weight: 500;
    }
  }
`

export const FormInput = styled(Input)`
  padding: 1.2em 1.5em;
  border-radius: 8px;
  border-color: #eaeaea !important;
  border-width: 2px;
  height: 60px;
  position: relative;
`
export const ImageItem = styled(Image)`
  border: 2px solid var(--elma-primary);
`
export const SpinnerLoading = styled.div`
height: 100dvh;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg ,rgb(0 0 0/50%) 30%,#0000 0 70%,rgb(0 0 0/100%) 0) 50%/8% 100%,
    linear-gradient(90deg,rgb(0 0 0/25%) 30%,#0000 0 70%,rgb(0 0 0/75% ) 0) 50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 2s infinite steps(12);
}
.loader::before,
.loader::after {
   content: "";
   grid-area: 1/1;
   border-radius: 50%;
   background: inherit;
   opacity: 0.915;
   transform: rotate(30deg);
}
.loader::after {
   opacity: 0.83;
   transform: rotate(60deg);
}
@keyframes l23 {
  100% {transform: rotate(1turn)}
}
`
export const SuccessContainer = styled.div`
    height: 100dvh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const RecuContainerPane = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding:10px 0px;
    // align-items: center;
`
export const Containt = styled.div`
    text-align: center;
`
export const LinkText = styled(Typography.Paragraph)`
    margin-top: 10px;
    color: var(--elma-text);
    font-weight: 600;
    text-align: center;

    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
`
export const RecuContainer = styled.div`
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    width: 700px;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`
export const FlexCol = styled(Col)`
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    color: var(--elma-primary);
    cursor: pointer;
    border: 1px solid #555 !important;
    margin-left: 5px;
    padding: 5px 15px !important;
    border-radius: 5px !important;
    transition: all ease 200ms;
    &:hover{
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    };
    &:active{
      transform: scale(.98)
    }
`
export const LabelText = styled(Typography.Text)`
    color: rgba(0, 0, 0,.5);
    font-size: 16px;
    @media print{
      color: #000000;
      font-size: 12px;
    }
`
export const DescriptionText = styled(Typography.Text)`
    color: #121212;
    font-weight: 500;

    @media print{
      color: #000000;
      font-weight: 500;
    }
`
export const MontantText = styled(Typography.Text)`
  font-size: 20px;
  font-weight: 600;

    @media print{
      color: #000000;
      font-size: 20px;
      font-weight: 600;
    }
`
export const RemiseText = styled(Typography.Paragraph)`
    color: #555;
    font-size: 12px;
    text-decoration: line-through;
    font-weight: 500;
`
export const ApptTable = styled(Table)`
  border: solid 1px #f4f4f4;
  border-radius: 3px;
  .table-row-light {
    background-color: unset;
  }
  .table-row-dark {
    background-color: #f9f9f9;
  }
  .ant-table-cell {
    padding: 12px 30px !important;
    white-space: nowrap;
  }
  .ant-table-thead {
    .ant-table-cell {
      color: var(--elma-primary);
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      &:first-child {
        border-top-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
      }
      .ant-table-column-sorters {
        padding-left: 0;
      }
    }
  }
  .ant-table-tbody {
    .ant-table-row .ant-table-cell-with-append {
      display: flex;
      align-items: center;
      height: 70px;
    }
    .ant-table-expanded-row {
      .ant-table-row {
        .ant-table-cell {
          background-color: unset;
          height: 90px;
        }
      }
    }
  }
`
export const InfoDescription = styled(Typography.Paragraph)`
  font-size: 12px;
  font-style: italic;
  margin: 5px 0 !important;
`
export const Printer = styled.div`
  background-color: #ffffff;
  padding: 50px;
`

export const BtnContainer = styled.div`
  margin-top: 20px;
`