import { USER_SEX, TYPE_CLIENT } from "enums/constants"
import { useFormik } from "formik"
import useSalonId from "hooks/useSalonId"
import * as Yup from "yup"
import { useAddClientMutation } from "actions/clients"

export const clientValidationSchema = () => {
  return Yup.object({
    first_name: Yup.string().required("Prénom est requis"),
    last_name: Yup.string(),
    phone_object: Yup.object()
      .shape({
        phone: Yup.string()
          .min("5", "Numero non valid.")
          .matches(/([(+]*[0-9]+[()+. -]*)/g, "Numero non valid.")
          .required("numéro de téléphone est requis"),
      })
      .required(),
    sexe: Yup.string().required("Sexe est requis"),
    type_client: Yup.string().required("Type d'utilisateur requis"),
    email: Yup.string().email("Email non valid."),
  })
}

const initialValues = {
  first_name: "",
  last_name: "",
  phone_object: {},
  type_client: TYPE_CLIENT.Adulte,
  sexe: USER_SEX.Femme,
  email: "",
}

/**
 *
 * @param {function} callback - callback to call after submission
 */
const useClientCreateForm = (callback = () => {}) => {
  const salonId = useSalonId()
  const { mutateAsync: addClient } = useAddClientMutation()

  return useFormik({
    clientValidationSchema,
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const newClient = { salonId, user: values }
        const response = await addClient(newClient)
        callback(response)
      } catch (err) {
        console.error(err)
      }
      finally {
        setSubmitting(false)
      }
    },
    validateOnMount: false,
  })
}

export default useClientCreateForm
