import React from 'react'
import CaisseVenteRapide from './CaisseVenteRapide';
import CaisseForfait from './CaisseForfait';
import * as Styled from "../../styles"

const CaisseVente = () => {
    const items = [
        {
            key: '1',
            label: 'Vente rapide',
            children: <CaisseVenteRapide />,
        },
        {
            key: '2',
            label: 'Packs et Forfaits',
            children: <CaisseForfait />,
        }
    ];
    return (
        <Styled.VenteTabs
            defaultActiveKey="1"
            items={items}
            // onChange={onChange}
            indicatorSize={(origin) => origin - 16}
        />
    )
}

export default CaisseVente