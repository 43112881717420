import { PERIODS_FILTER } from "components/Dashboard/DashboardFilter/utils"
import moment from "moment"

export const statistiquesInitialState = {
    period: PERIODS_FILTER.MONTH,
    date: moment().format("DD-MM-YYYY")
}

/**
 * @enum
 */
export const STATISTIQUE_REDUCER_ACTION_TYPE = {
    CHANGE_PERIOD: "CHANGE_PERIOD",
    CHANGE_DATE: "CHANGE_DATE",
}

export const statistiquesReducer = (state, action) => {
    switch (action.type) {
        case STATISTIQUE_REDUCER_ACTION_TYPE.CHANGE_PERIOD: {
            return {
                ...state,
                period: action.payload
            }
        }
        case STATISTIQUE_REDUCER_ACTION_TYPE.CHANGE_DATE: {
            return {
                ...state,
                date: moment(action.payload).format("DD-MM-YYYY")
            }
        }
    }
}