import styled from "styled-components"
import { Typography } from "antd"
export const ChildrenContainer = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 878px) {
    justify-content: flex-end;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`
export const TitleContainer = styled.div`
  display: block;
  width: 50%;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 7px;
  @media screen and (max-width: 878px) {
    width: 100%;
    margin-bottom: ${({ children }) => (children ? "10px" : "")};
  }
`
export const HeaderTitle = styled(Typography.Title)`
  margin: 0 !important;
  margin-right: 2em;
  font-size: 1.7em !important;
  font-weight: bolder !important;
  color: #fff !important;
  width: ${({ children }) => (children ? "" : "60%")};
  &::before {
    float: right;
    width: calc(100% - 10em);
    height: 1em;
    content: "";
  }
`
export const Container = styled.div`
  padding: 1.3em;
  min-height: 100px;
  align-items: center;
  width: 100%;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background-color: var(--elma-primary);
  @media screen and (max-width: 768px) {
    height: auto;
  }
  @media screen and (max-width: 878px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`
