import { salonStorage } from "utils";

const useSalonId = () => {
  const salon = salonStorage.get()
  // console.log({ salon })
  // if (!salon) {
  //   throw new Error("Salon not found")
  // }
  return salon?.currentSalon?.id
}

export default useSalonId
