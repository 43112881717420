import request from 'utils/request';

const createVenteRapide = ({ salonId, data }) => {
    if (!salonId) throw new Error("salonId is required in createVenteRapide")
    return request({
        url: `backoffice/salons/${salonId}/direct_sales/new`,
        data,
        method: "POST"
    })
}

export const venteRapideApi = {
    createVenteRapide
}